import React, { useEffect } from "react";
import NavbarNow from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const BiogasLongTerm = () => {
  useEffect(() => {
    document.title = "Yayasan Rumah Ilham Nusantara";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavbarNow />
      <div data-theme="light">
        <div className="h-fit flex bg-[#34d399] py-48 lg:py-56">
          <div className="my-10 lg:m-auto bg-[#dcfce7] p-8 rounded-lg text-center mx-auto container h-fit">
            <h1 className="md:text-3xl text-2xl lg:text-4xl font-bold">
              Long-Term Biogas Engineering Internship
            </h1>
            <ul className="pt-10 text-sm md:text-md lg:text-lg text-left inline-block">
              <li>📍Jogjakarta, Indonesia</li>
              <li>📆 6 months</li>
              <li>
                🏨 Accommodation & 3 meals a day (vegetarian catering available)
              </li>
              <li>🧑‍🎓 Ages 18-50+ (students have preference)</li>
            </ul>
            <div className="pt-10 grid grid-cols-1 md:grid-cols-2 gap-20 mx-10">
              <div>
                <h1 className="text-2xl font-bold">Introduction</h1>
                <ol className="list-decimal text-sm md:text-md lg:text-lg text-left inline-block">
                  <li>
                    This study program is very suitable for students who wish to
                    become volunteers in developing countries or work in
                    international NGOs with an emphasis on environmental
                    conservation. Each applicant will receive a certificate
                    confirming their skills in Biogas Construction and Organic
                    Farming upon completion of the program. The study period is
                    1 month.
                  </li>
                  <li>
                    Yogyakarta, where the study program is located, is a popular
                    city for tourism in Indonesia with two large temples
                    (Prambanan Temple and Borobudur Temple).
                  </li>
                  <li>
                    Rumah Ilham can accept a minimum of 5 students and a maximum
                    of 20 students per month.
                  </li>

                  <li>
                    After completing the study program and you still want to
                    live in Indonesia,{" "}
                    <span className="font-bold">
                      you can apply for an internship or become a volunteer for
                      all Rumah Ilham activities related to the environment and
                      renewable energy and you will only be charged for item No
                      1 in the table (catering). The price is different for 2x
                      meals a day or 3x meals a day - prices can be negotiated.
                    </span>{" "}
                    Visa extensions are at your expense.
                  </li>
                  <li>Catering for a vegetarian diet is possible</li>
                  <li>We have 2 mentors dedicated to help students.</li>
                  <li>
                    Program of Study:
                    <li>
                      Program of Study:
                      <ul className="font-bold">
                        <li>
                          <span className="text-2xl">•</span> Biogas theory : 5
                          days.
                        </li>
                        <li>
                          <span className="text-2xl">•</span> Biogas
                          construction : 8 days.
                        </li>
                        <li>
                          <span className="text-2xl">•</span> Bio-slurry for
                          compost and organic pesticides : 5 days.
                        </li>
                        <li>
                          <span className="text-2xl">•</span> Site visit : 2
                          days.
                        </li>
                      </ul>
                    </li>
                  </li>
                  <li>
                    Current activities at Rumah Ilham include providing training
                    on renewable energy, especially biogas and organic farming
                    using biogas slurry. We also engage in environmental
                    activities such as cleaning seas and rivers from plastic
                    waste and managing neighborhood plastic waste for the
                    benefit of the environment.
                  </li>
                  <li>
                    We have had 7 studentsat Rumah Ilham in the past : 3 from
                    Germany, 1 from the Netherlands, 1 from the UK, and 1 from
                    Rwanda. The study program closed due to the COVID-19
                    pandemic and is now running again.
                  </li>
                </ol>
              </div>

              <div>
                <h1 className="text-2xl font-bold">Requirements:</h1>
                <ul className="text-sm md:text-md lg:text-lg text-left inline-block list-disc">
                  <li>
                    Open to any applicant with no age restrictions, but students
                    or college students will have priority if space is limited.
                  </li>
                  <li>Physically and mentally healthy.</li>
                  <li>
                    Applicant must respect local culture and relevant laws in
                    Indonesia.
                  </li>
                  <li>Applicant must not consume illegal drugs.</li>
                  <li>
                    Disciplined and must follow Rumah Ilham's rules at all
                    times.
                  </li>
                  <li>
                    Willing and ready to stay in an Indonesian village for 7
                    days with villagers during biogas construction.
                  </li>
                  <li>
                    Willing to take part in waste collection activities in the
                    sea and rivers for the benefit of the environment.
                  </li>
                  <li>Deposit payable of 2 million IDR.</li>
                  <li>Must stay at the Training Center Rumah Ilham.</li>
                  <li>Arrange your own visa.</li>
                  <li>
                    If the visa is arranged by Rumah Ilham, the visa fee is 2
                    million rupiah and processing services are free of charge
                  </li>
                </ul>
              </div>
            </div>
            <div className="py-10">
              <h1 className="text-2xl font-semibold">Study Cost Details</h1>
              <div className="text-center pb-10 pt-2">
                <h1 className="text-2xl font-bold ">
                  REFERS TO POINT 4, INTRODUCTION
                </h1>
              </div>
              {/* <div class="flex flex-col rounded-md" data-theme="dark"></div> */}

              <Link
                className="mt-5 btn bg-[#34d399] transition ease-in-out lg:text-lg text-sm hover:bg-[#dcfce7] text-black font-semibold py-2 px-4 border border-black border-b-4 border-r-4 rounded-lg duration-500 hover:border-black"
                to="https://forms.gle/38nKucqhG6cqXkXH6"
              >
                Make a reservation
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BiogasLongTerm;
