import React from "react";

const Map = () => {
  return (
    <div className="bg-[#34d399] pt-20">
      <div className="bg-[#34d399]" data-theme="light">
        <div className="w-[65%] mx-auto">
          <h1 className="text-2xl lg:text-3xl font-bold uppercase pb-5 text-center lg:text-left">
            Find Us!
          </h1>
        </div>
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13685619.403377911!2d108.41086640094865!3d-5.200057910020017!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a59645ac4fe47%3A0xc840cb59ee05146!2sRumah%20Ilham%20Desa%20Kancilan!5e0!3m2!1sen!2sid!4v1704713025755!5m2!1sen!2sid"
          height="650"
          className="w-full"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="map"
        ></iframe>
      </div>
    </div>
  );
};

export default Map;
