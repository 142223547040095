import React from "react";

const Partners = () => {
  return (
    <div className="bg-[#dcfce7] pb-8" data-theme="light">
      <h1 className="text-center text-xl lg:text-3xl py-8 font-semibold">
        Our Partnership
      </h1>
      <div class="px-4 mx-auto sm:max-w-xl md:max-w-full md:px-24 lg:px-8">
        <div class="grid grid-cols-1 row-gap-8 gap-y-8 lg:grid-cols-3">
          <div
            class="text-center sm:border-r-[3px] border-black"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <img src="images/partners/TechWithoutBorders.png" className="text-center mx-auto h-[160px] w-auto" alt="partner1" />
            <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">Technology Without Borders</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
              Since 2018
            </p>
          </div>
          <div
            class="text-center lg:border-r-[3px] border-black"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <img src="images/partners/FatherDamienSociety.png" className="text-center mx-auto h-[140px] mb-5 w-auto" alt="partner2" />
            <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">Father Damien Society</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
              Since 2018
            </p>
          </div>
          <div
            class="text-center"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <img src="images/partners/got_bag.png" className="text-center mx-auto h-[160px] w-auto" alt="partner3" />
            <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">GOT BAG</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
              July 2019 - Sept 2022
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
