import { Link } from "react-router-dom";

function ButtonPricing(item) {
  if (item.item.button === "Get Started") {
    return (
      <>
        <div class="p-6">
          <h1 class="text-xl font-medium text-gray-700 capitalize lg:text-2xl dark:text-white">
            {item.item.title}
          </h1>

          <p class="mt-4 text-gray-500 dark:text-gray-300">Start from</p>

          <h2 class="mt-1 text-2xl font-semibold text-gray-700 sm:text-3xl dark:text-gray-300">
            {item.item.price}
          </h2>
          <Link to={item.item.link}>
            <button class="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
              View Details
            </button>
          </Link>
        </div>

        <hr class="border-gray-200 dark:border-gray-700" />

        <div class="p-6">
          <h1 class="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">
            What's included:
          </h1>

          <div class="mt-8 space-y-4">
            {item.item.list &&
              item.item.list.map((i, key) => {
                return (
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="text-blue-500 flex-shrink-0 w-5 h-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <span class="mx-4 text-gray-700 dark:text-gray-300">
                      {i}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div class="p-6">
          <h1 class="text-xl font-medium text-gray-700 capitalize lg:text-2xl dark:text-white">
            {item.item.title}
          </h1>
          <p class="mt-4 text-gray-500 dark:text-gray-300"></p>
          <h2 class="mt-1 text-2xl font-semibold text-gray-700 sm:text-3xl dark:text-gray-300">
            {item.item.button}
          </h2>
        </div>

        <hr class="border-gray-200 dark:border-gray-700" />
      </>
    );
  }
}

const ActivityCard = ({ props }) => {
  return (
    <div class="bg-[#34d399]" data-theme="light" id="pricing">
      <div class="container px-6 mx-auto">
        <div class="mx-auto ">
          <div class="text-center xl:mx-8 pb-4">
            <h1 class="text-2xl font-medium text-gray-800 capitalize lg:text-3xl">
              Our Renewable Energy Volunteer Internships
            </h1>

            <div class="mt-4">
              <span class="inline-block w-40 h-1 bg-[#dcfce7] rounded-full"></span>
              <span class="inline-block w-3 h-1 mx-1 bg-[#dcfce7] rounded-full"></span>
              <span class="inline-block w-1 h-1 bg-[#dcfce7] rounded-full"></span>
            </div>
          </div>

          <div class="flex-1 ">
            <div class="mt-8 space-y-8 md:-mx-4 md:flex md:items-start md:justify-center md:space-y-0 xl:mt-0">
              {props.map((item, key) => {
                return (
                  <div class="max-w-sm mx-auto rounded-lg md:mx-4 shadow-lg bg-white">
                    <ButtonPricing item={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div className="w-[65%] mx-auto">
    //     <h1 className="text-2xl lg:text-3xl font-bold uppercase pb-14 text-center lg:text-left">Our Renewable Energy Volunteer Internships</h1>
    //     <div className="grid lg:grid-cols-3 gap-8">
    //         {props.map((item, key) => (
    //             <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow-lg xl:p-8 h-full">
    //                 <h3 class="mb-4 text-2xl font-semibold">{item.title}</h3>
    //                 <ul>
    //                     {item.description && item.description.map((i, index) => (
    //                         <li key={index} className="flex items-center space-x-3 text-left">
    //                             <span>{i}</span>
    //                         </li>
    //                     ))}
    //                 </ul>
    //                 <div class="flex justify-center items-baseline my-8">
    //                     <span class="mr-2 text-5xl font-extrabold">{item.price}</span>
    //                 </div>
    //                 <ul class="my-8 space-y-4 text-left">
    //                 {item.list && item.list.map((i, index) => (
    //                     <li key={index} className="flex items-center space-x-3">
    //                         <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    //                             <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
    //                         </svg>
    //                         <span>{i}</span>
    //                     </li>
    //                 ))}
    //                 </ul>
    //                 <ButtonPricing buttonText={item.button} />
    //             </div>
    //         ))}
    //     </div>
    // </div>
  );
};

export default ActivityCard;
