import ActivityCard from "./ActivityCard";

const Activities = () => {
  const activityList = [
    {
      title: "Biogas Engineering Internship (Short-Term)",
      link: "/short-term-biogas",
      price: "€650 / $680",
      list: [
        "Enhance your CV and gain professional hands-on experience engineering renewable energy projects and having a direct, positive impact on vulnerable communities",
        "Enjoy a 7-day experience in a small village in Java planning, building and starting up a fixed-dome biogas digester",
        "Live in a semi self-sustaining homestay in a touristic paradise in Indonesia in custom-made bamboo accommodation and explore world-famous heritage sites such as the temples of Prambanan and Borobudur",
      ],
      button: "Get Started",
    },
    {
      title: "Biogas Engineering Internship (Long-Term)",
      link: "/long-term-biogas",
      price: "€650++ / $680++",
      list: [
        "Enhance your CV and gain professional hands-on experience engineering renewable energy projects and having a direct, positive impact on vulnerable communities",
        "Enjoy a 7-day experience in a small village in Java planning, building and starting up a fixed-dome biogas digester",
        "Live in a semi self-sustaining homestay in a touristic paradise in Indonesia in custom-made bamboo accommodation and explore world-famous heritage sites such as the temples of Prambanan and Borobudur",
        "Get assistance for a visa application and processing services for an extended stay in Indonesia",
        "Stay for six months internship.",
        "Learn Javanese culture and Indonesian language",
        "Improve your biogas construction skill by visiting several new biogas construction sites",
        "Visit some farmings for learning slurry applications as organic fertilizer and pesticide",
        "Visit some location in north and south Java for involving in plastic waste collection"
      ],
      button: "Get Started",
    },
    {
      title: "Ocean Plastic Internship (Short & Long-Term)",
      caption:
        "We also clean river and ocean that have been contaminated with plastic wastes",
      alt: "activity3",
      button: "Coming Soon",
    },
  ];

  return (
    <div className="internships" id="internships">
      <div className="bg-[#dcfce7]">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 280">
          <path
            fill="#34d399"
            fill-opacity="1"
            d="M0,128L60,138.7C120,149,240,171,360,160C480,149,600,107,720,112C840,117,960,171,1080,176C1200,181,1320,139,1380,117.3L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div
        className="bg-[#34d399] pb-20"
        data-theme="light"
      >
        <ActivityCard props={activityList} />
      </div>
    </div>
  );
};

export default Activities;
