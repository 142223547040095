import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Yayasan Rumah Ilham Nusantara";
    window.scrollTo(0, 0);

    axios
      .get("http://localhost:4000/logout", { withCredentials: true })
      .then((res) => {
        setTimeout(() => {
          navigate("/admin_login");
        }, 2000);
      })
      .catch((err) => {
        navigate("/dashboard");
      });
  }, [navigate]);

  return (
    <div className="h-screen flex items-center justify-center bg-gray-300" data-theme='light'>
      <span className="loading loading-spinner loading-lg"></span>
    </div>
  );
};

export default Logout;
