import "./App.css";
import Homepage from "./pages/Homepage";
import Gallery from "./pages/Gallery";
import Reservation from "./pages/Reservation";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BiogasShortTerm from "./pages/BiogasShortTerm";
import Register from "./pages/Register";
import AboutPage from "./pages/AboutPage";
import Internships from "./pages/Internships";
import AdminLogin from "./pages/AdminLogin";
import Dashboard from "./pages/Dashboard";
import Logout from "./pages/Logout";
import Profile from "./pages/Profile";
import ChangePassword from "./pages/ChangePassword";
import CustomerList from "./pages/CustomerList";
import BiogasLongTerm from "./pages/BiogasLongTerm";

function App() {
  return (
    <div className="overflow-x-hidden">
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/internships" element={<Internships />} />
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/short-term-biogas" element={<BiogasShortTerm />} />
          <Route path="/long-term-biogas" element={<BiogasLongTerm />} />
          <Route path="/register" element={<Register />} />

          <Route path="/admin_login" element={<AdminLogin />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/change_password" element={<ChangePassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/reservation_list" element={<CustomerList />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
