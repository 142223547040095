import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@material-tailwind/react";
// import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

const CustomerList = () => {
  const navigate = useNavigate();
  const itemsPerPage = 5;
  const [active, setActive] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);
  const [size, setSize] = useState(null);

  const handleOpen = (value) => {
    setSize(value);
  };

  const [data, setData] = useState([]);
  const [dataSliced, setDataSliced] = useState([]);
  const [loading, setLoading] = useState(true);

  const getItemProps = (index) => ({
    variant: active === index ? "filled" : "text",
    color: "gray",
    onClick: () => setActive(index),
  });

  // const next = () => {
  //   if (!(active === 1)) {
  //     setActive(active + 1);
  //   }
  // };

  // const prev = () => {
  //   if (active === totalPages) return;

  //   setActive(active - 1);
  // };

  useEffect(() => {
    document.title = "Admin Page - Yayasan Rumah Ilham Nusantara";
    window.scrollTo(0, 0);

    axios
      .get("http://localhost:4000/list_customer", { withCredentials: true })
      .then((res) => {
        // navigate("/dashboard");
        setData(res.data.reservation);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
        setEndIndex(active * itemsPerPage);
        setStartIndex(endIndex - itemsPerPage);
        setDataSliced(data.slice(startIndex, endIndex));
        // console.log(data);
      })
      .catch((err) => {
        navigate("/dashboard");
      });

    setTimeout(() => setLoading(false), 1000);
  }, [
    navigate,
    data.length,
    active,
    data,
    endIndex,
    totalPages,
    itemsPerPage,
    startIndex,
  ]);

  if (loading) {
    return (
      <div
        className="h-screen flex items-center justify-center bg-gray-300"
        data-theme="light"
      >
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  return (
    <div data-theme="light" className="bg-gray-300">
      <div className="p-4 fixed z-10">
        <Sidebar />
      </div>

      <div className="h-screen flex items-center justify-center">
        <div className="w-full">
          <div class="relative overflow-x-auto shadow-md mx-20 pt-5">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    Full Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Phone Number
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Country
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Start Date
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Internship Type
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Contacted
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataSliced.map((comp) => {
                  return (
                    <tr
                      key={comp.id}
                      class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                    >
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {comp.first_name} {comp.last_name}
                      </th>
                      <td class="px-6 py-4">{comp.email}</td>
                      <td class="px-6 py-4">{comp.telephone_num}</td>
                      <td class="px-6 py-4">{comp.country}</td>
                      <td class="px-6 py-4">{comp.start_date}</td>
                      <td class="px-6 py-4">{comp.internship_type}</td>
                      <td className="px-6 py-4">
                        <div class="flex items-center">
                          <Button
                            onClick={() => handleOpen("xs")}
                            variant="gradient"
                          >
                            Open Dialog
                          </Button>
                        </div>
                      </td>
                      <Dialog
                        key={comp.id}
                        open={
                          size === "xs" ||
                          size === "sm" ||
                          size === "md" ||
                          size === "lg" ||
                          size === "xl" ||
                          size === "xxl"
                        }
                        size={size || "md"}
                        handler={handleOpen}
                      >
                        <DialogHeader>Confirm Action</DialogHeader>
                        <DialogBody>
                          Are you sure you want to continue?
                        </DialogBody>
                        <DialogFooter>
                          <Button
                            variant="text"
                            color="red"
                            onClick={handleOpen}
                            className="mr-1"
                          >
                            <span>Cancel</span>
                          </Button>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              alert(e.target[`name${comp.id}`].value);
                            }}
                          >
                            <input
                              type="text"
                              name={`name${comp.id}`}
                              id={`name${comp.id}`}
                              hidden
                              value={comp.id}
                            />
                            <Button
                              variant="gradient"
                              color="green"
                              type="submit"
                            >
                              <span>Confirm</span>
                            </Button>
                          </form>
                        </DialogFooter>
                      </Dialog>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="flex items-center justify-center py-5 gap-4">
            <div className="flex items-center gap-2">
              {Array.from(Array(totalPages), (e, i) => {
                return (
                  <IconButton {...getItemProps(i + 1)}>{i + 1}</IconButton>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
