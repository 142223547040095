import React, { useEffect } from "react";
import NavbarNow from "../components/Navbar";

export default function Gallery(props) {
  useEffect(() => {
    document.title = "Yayasan Rumah Ilham Nusantara";
    window.scrollTo(0, 0);
  }, []);

  const data = [
    { url: "/images/about-slider/slider1.jpg", title: "gambar1" },
    { url: "/images/about-slider/slider2.jpg", title: "gambar2" },
    { url: "/images/about-slider/slider3.jpg", title: "gambar3" },
    { url: "/images/about-slider/slider4.jpg", title: "gambar4" },
    { url: "/images/about-slider/slider5.jpg", title: "gambar5" },
    { url: "/images/about-slider/slider6.jpg", title: "gambar6" },
    { url: "/images/about-slider/slider7.jpg", title: "gambar7" },
    { url: "/images/gallery/gallery1.jpg", title: "gambar8" },
    { url: "/images/gallery/gallery2.jpg", title: "gambar9" },
    { url: "/images/gallery/gallery3.jpg", title: "gambar10" },
    { url: "/images/gallery/gallery4.jpg", title: "gambar11" },
    { url: "/images/gallery/gallery5.jpg", title: "gambar12" },
    { url: "/images/gallery/gallery6.jpg", title: "gambar13" },
    { url: "/images/gallery/gallery7.jpg", title: "gambar14" },
  ];

  return (
    <>
      <NavbarNow />
      <div
        className="hero h-96 bg-center"
        style={{
          backgroundImage: "url(images/hero1.jpg)",
          backgroundPositionY: "-200px",
        }}
      >
        <div className="hero-overlay bg-opacity-60"></div>
        <div className="hero-content text-center text-neutral-content">
          <div className="max-w-md">
            <h1 className="text-5xl font-bold mt-16">Gallery</h1>
          </div>
        </div>
      </div>
      <div className="bg-[#34d399]">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 p-10">
          {data.map((comp, index) => (
            <div key={index}>
              <img
                className="h-96 w-full max-w-full rounded-lg object-cover object-center shadow-md"
                src={comp.url}
                alt={comp.title}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
