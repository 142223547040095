import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import Sidebar from "../components/Sidebar";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState("");
  const [success, setSuccess] = useState(false);

  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
  });

  useEffect(() => {
    document.title = "Admin Page - Yayasan Rumah Ilham Nusantara";
    window.scrollTo(0, 0);

    axios
      .get("http://localhost:4000/login", { withCredentials: true })
      .then((res) => {
        // navigate("/dashboard");
        
      })
      .catch((err) => {
        navigate("/admin_login")
      });

    setTimeout(() => setLoading(false), 1000);
  }, [navigate]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setPassword((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    axios
      .post("http://localhost:4000/change_password", password, {
        withCredentials: true,
      })
      .then((res) => {
        setResponse("Password has been changed");
        setSuccess(true);
        // navigate("/dashboard");
      })
      .catch((err) => {
        setResponse("Error occured while changing password");
        setSuccess(false);
      });
  };

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-300" data-theme='light'>
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  return (
    <div className="bg-gray-300" data-theme="light">
      <div className="p-4 fixed">
        <Sidebar />
      </div>
      <div className="h-screen flex items-center justify-center">
        <div className="bg-white p-5 rounded-md shadow-lg w-3/4 md:w-1/2 lg:w-1/4">
          <Link to={"/profile"} className="flex">
            <ArrowLeftIcon className="h-5 w-5 mx-2" /> Go Back
          </Link>
          <h1 className="text-2xl font-bold text-center">Change Password</h1>
          <form class="max-w-sm mx-auto pt-5" onSubmit={onSubmit}>
            <div class="mb-5">
              <input
                type="password"
                value={password.oldPassword}
                onChange={onChange}
                id="oldPassword"
                name="oldPassword"
                placeholder="Current Password"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
              <input
                type="password"
                value={password.newPassword}
                onChange={onChange}
                id="newPassword"
                name="newPassword"
                placeholder="New Password"
                class="bg-gray-50 mt-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            {success ? (
              <h1 className="my-5 text-green-600 font-semibold">{response}</h1>
            ) : (
              <h1 className="my-5 text-red-600 font-semibold">{response}</h1>
            )}

            <button
              type="submit"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
