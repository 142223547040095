import React from "react";
import NavbarNow from "../components/Navbar";
import axios from "axios";
import { useState, useEffect } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

const Reservation = () => {
  const navigate = useNavigate();

  const countriesUrl = "https://restcountries.com/v3.1/all?fields=name";
  const [listCountries, setListCountries] = useState([]);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    program: "",
    date: {
      startDate: null,
      endDate: null,
    },
  });

  const [error, setError] = useState(null);

  const handleDateChange = (newValue) => {
    // console.log("newValue:", newValue);
    // setDate(newValue);
    setData((prev) => {
      return {
        ...prev,
        date: newValue,
      };
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  function onSubmit(e) {
    e.preventDefault();

    axios
      .post("http://localhost:4000/reservation_made", data)
      .then((res) => {
        console.log(res.data);
        document.getElementById('my_modal_1').showModal()
      })
      .catch((err) => {
        console.log(err.response.data.error);
        setError(err.response.data.error);
      })
      .catch(() => {
        setError("Error");
      });
  }

  function modalOnClick(e){
    e.preventDefault();

    navigate("/");
  }

  useEffect(() => {
    document.title = "Yayasan Rumah Ilham Nusantara";
    window.scrollTo(0, 0);

    axios
      .get(countriesUrl)
      .then((res) => {
        setListCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const sortedCountries = listCountries.sort((a, b) => {
    const nameA = a.name.common.toUpperCase(); // ignore case
    const nameB = b.name.common.toUpperCase(); // ignore case
    return nameA.localeCompare(nameB);
  });

  return (
    <>
      <div data-theme="light">
        <NavbarNow />
        <div className="h-screen flex items-center justify-center bg-[#34d399]">
          <form class="max-w-md mx-auto bg-white p-10 rounded-md">
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  onChange={onChange}
                  value={data.firstName}
                />
                <label
                  for="firstName"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  First name
                </label>
              </div>
              <div class="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  onChange={onChange}
                  value={data.lastName}
                />
                <label
                  for="lastName"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Last name
                </label>
              </div>
            </div>
            <div class="relative z-0 w-full mb-5 group">
              <input
                type="email"
                name="email"
                id="email"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={onChange}
                value={data.email}
              />
              <label
                for="email"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Email address
              </label>
            </div>
            <div class="relative z-0 w-full mb-5 group">
              <input
                type="tel"
                name="phone"
                id="phone"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={onChange}
                value={data.phone}
              />
              <label
                for="phone"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Phone number
              </label>
            </div>
            <div class="relative z-0 w-full mb-5 group">
              <label for="country" class="sr-only">
                Country
              </label>
              <select
                id="country"
                name="country"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                value={data.country}
                onChange={onChange}
                required
              >
                <option selected>Choose a country</option>
                {sortedCountries.map((e) => {
                  return <option value={e.name.common}>{e.name.common}</option>;
                })}
              </select>
            </div>

            <div class="relative z-0 w-full mb-5 group">
              <label for="program" class="sr-only">
                Underline select
              </label>
              <select
                id="program"
                name="program"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                value={data.program}
                onChange={onChange}
                required
              >
                <option selected>Choose an internship program</option>
                <option value="1">Short-Term Biogas</option>
                <option value="2">Long-Term Biogas</option>
              </select>
            </div>

            <div className="border-b-2 border-gray-300 mb-5">
              <Datepicker
                value={data.date}
                onChange={handleDateChange}
                useRange={false}
                asSingle={true}
                minDate={new Date()}
                displayFormat={"DD/MM/YYYY"}
                popoverDirection="down"
                inputClassName="text-gray-500 w-full p-2 placeholder-gray-500"
                placeholder="Start Date"
                inputName="date"
              />
            </div>

            <h1 className="my-5 text-red-600 font-semibold">{error}</h1>
            <button
              type="submit"
              onClick={onSubmit}
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
            <dialog id="my_modal_1" className="modal">
              <div className="modal-box">
                <h3 className="font-bold text-lg">You have reserved your place!</h3>
                <p className="py-4">
                  Click the button below to go to the homepage.
                </p>
                <div className="modal-action">
                  <form method="dialog">
                    {/* if there is a button in form, it will close the modal */}
                    <button className="btn" onClick={modalOnClick}>Go Back</button>
                  </form>
                </div>
              </div>
            </dialog>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Reservation;
