import React, { useEffect } from "react";
import NavbarNow from "../components/Navbar";
import Footer from "../components/Footer";
import Partners from "../components/Partners";

const AboutPage = () => {
  useEffect(() => {
    document.title = "Yayasan Rumah Ilham Nusantara";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div data-theme="light">
        <NavbarNow />
        <div
          className="hero h-96 bg-center"
          style={{
            backgroundImage: "url(images/hero1.jpg)",
            backgroundPositionY: "-200px",
          }}
        >
          <div className="hero-overlay bg-opacity-60"></div>
          <div className="hero-content text-center text-neutral-content">
            <div className="max-w-md">
              <h1 className="text-5xl font-bold mt-16">About Us</h1>
            </div>
          </div>
        </div>

        <Partners />

        <div className="bg-[#dcfce7] pb-4" data-theme="light">
          <div class="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full md:px-24 lg:px-8 lg:pt-10 bg-[#dcfce7]">
            <div class="grid grid-cols-1 sm:grid-cols-2 row-gap-8 gap-y-8 lg:grid-cols-7">
              <div
                class="text-center sm:border-r-[3px] border-black"
                data-aos="fade-up"
                data-aos-duration="700"
              >
                <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">714</h6>
                <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
                  of Biogas Digesters Installed
                </p>
              </div>
              <div
                class="text-center lg:border-r-[3px] border-black"
                data-aos="fade-up"
                data-aos-duration="700"
              >
                <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">2.856</h6>
                <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
                  of Tonnes of CO2 Captured
                </p>
              </div>
              <div
                class="text-center sm:border-r-[3px] border-black"
                data-aos="fade-up"
                data-aos-duration="700"
              >
                <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">8</h6>
                <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
                  of Rivers Benefited
                </p>
              </div>
              <div
                class="text-center lg:border-r-[3px] border-black"
                data-aos="fade-up"
                data-aos-duration="700"
              >
                <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">16</h6>
                <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
                  of Villages Benefited
                </p>
              </div>
              <div
                class="text-center sm:border-r-[3px] border-black"
                data-aos="fade-up"
                data-aos-duration="700"
              >
                <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">200km</h6>
                <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
                  of Ocean and Coastal benefited
                </p>
              </div>
              <div
                class="text-center lg:border-r-[3px] border-black"
                data-aos="fade-up"
                data-aos-duration="700"
              >
                <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">
                  281.547
                </h6>
                <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
                  Tons of Plastic Waste Collected From The Rivers
                </p>
              </div>
              <div
                class="text-center"
                data-aos="fade-up"
                data-aos-duration="700"
              >
                <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">
                  361.273
                </h6>
                <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
                  Tons of Plastic Waste Collected From The Sea and Along
                  Seashore
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr className="border-black md:hidden " />

        <div className="bg-[#dcfce7]">
          <div className="md:mx-24 mx-8 py-10">
            <div className="place-items-center grid xl:grid-cols-2 grid-cols-1 gap-x-24">
              <div className="text-left">
                <h1 className="font-bold text-lg xl:text-2xl 2xl:text-4xl text-center xl:text-left">
                  This is Where Rumah Ilham Comes in
                </h1>
                <p className="my-3 text-sm xl:text-md 2xl:text-xl text-center xl:text-left">
                  We are one of the only NGOs in Indonesia tackling the problems
                  of environmental pollution at the household level. In our home
                  of Jogjakarta there's an estimated 300,000 heads of cattle.
                  Our biogas digesters turn cow waste from a toxic source of
                  water pollution into high-quality organic compost that can
                  also fully replace fossil fuel-based fertilisers. <br />
                  <br />
                  Aside from promoting organic agriculture, the biogas is used
                  to heat water and power a range of innovative and
                  environmentally friendly side businesses for families that
                  desperately need it. We also work in fishing communities
                  helping them earn more than their regular daily income by
                  fishing plastic waste from the Java Sea. <br />
                  <br />
                  We believe that Indonesia's problems with environmental waste
                  can be a blessing-in-disguise when tackled in the right way.{" "}
                  <br />
                  <br />
                  <br />
                </p>

                <h1 className="font-bold text-lg xl:text-2xl 2xl:text-4xl text-center xl:text-left">
                  This is Where YOU Come in
                </h1>
                <p className="my-3 text-sm xl:text-md 2xl:text-xl text-center xl:text-left">
                  The cost of a fixed dome biogas digester is over 3 times the
                  annual minimum wage in Jogjakarta, Indonesia. For what is less
                  than a month's minimum wage in the Western World, your
                  internship's costs will support one rural family's transition
                  to biogas. <br />
                  <br />
                  In return, we promise to ignite your passion for
                  sustainability with a rare experience of hands-on renewable
                  energy engineering in a part of the world that desperately
                  needs your help.
                  <br />
                  <br />
                  <br />
                </p>
              </div>
              <div className="text-right">
                <img
                  className="rounded-md shadow-xl object-cover"
                  src="/images/home-slider/slider3.jpg"
                  alt="gambar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
