import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "../components/Sidebar";

const Profile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState("");
  const [username, setUsername] = useState({
    username: "",
  });

  useEffect(() => {
    document.title = "Admin Page - Yayasan Rumah Ilham Nusantara";
    window.scrollTo(0, 0);

    axios
      .get("http://localhost:4000/edit_profile", { withCredentials: true })
      .then((res) => {
        // navigate("/dashboard");
        // console.log(res.data.username)
        setUsername({
          username: res.data.username,
        });
      })
      .catch((err) => {
        navigate("/admin_login");
      });

    setTimeout(() => setLoading(false), 1000);
  }, [navigate]);

  function handleSubmit(e) {
    e.preventDefault();

    axios
      .post("http://localhost:4000/edit_profile", username, {
        withCredentials: true,
      })
      .then((res) => {
        setSuccess("The username has been changed!");
      })
      .catch((err) => {

      });
  }

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-300" data-theme='light'>
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  return (
    <div className="bg-gray-300" data-theme="light">
      <div className="p-4 fixed">
        <Sidebar />
      </div>
      <div className="h-screen flex items-center justify-center">
        <div className="bg-white p-5 rounded-md shadow-lg w-3/4 md:w-1/2 lg:w-1/4">
          <h1 className="text-2xl font-bold text-center">Admin Profile</h1>
          <form class="max-w-sm mx-auto pt-5" onSubmit={handleSubmit}>
            <div class="mb-5">
              <label
                for="username"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Username
              </label>
              <input
                type="text"
                value={username.username}
                onChange={(e) => setUsername({ username: e.target.value })}
                id="username"
                name="username"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <h1 className="my-5 text-green-600 font-semibold">{success}</h1>
            <div className="grid grid-cols-2 gap-3">
              <button
                type="submit"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Change Username
              </button>
              <Link to={"/change_password"}>
                <button
                  class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Change Password
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
