import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

const About = () => {
  const photos = [
    { url: "/images/about-slider/slider1.jpg", title: "gambar1" },
    { url: "/images/about-slider/slider2.jpg", title: "gambar2" },
    { url: "/images/about-slider/slider3.jpg", title: "gambar3" },
    { url: "/images/about-slider/slider4.jpg", title: "gambar4" },
    { url: "/images/about-slider/slider5.jpg", title: "gambar5" },
    { url: "/images/about-slider/slider6.jpg", title: "gambar6" },
    { url: "/images/about-slider/slider7.jpg", title: "gambar7" },
  ];

  return (
    <div className="bg-[#dcfce7] about" data-theme="light" id="about">
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:translate-y-[15%] h-fit lg:max-h-screen">
        <div
          className="w-full lg:h-full h-fit"
          data-aos="fade-down-right"
          data-aos-duration="1500"
        >
          <Swiper
            centeredSlides={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper object-cover lg:h-1/2 h-3/4 lg:rounded-r-lg lg:shadow-xl"
          >
            {photos.map((item, key) => (
              <SwiperSlide key={key}>
                <img
                  src={item.url}
                  alt={item.title}
                  className="object-cover w-full"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div
          className="mx-10 lg:ms-10 lg:me-0 lg:bg-white lg:rounded-l-lg lg:shadow-xl lg:h-fit lg:p-8 lg:bg-opacity-50 z-10 md:translate-y-[-20%] lg:translate-y-0 h-full lg:mt-0 mt-5"
          data-aos="fade-down-left"
          data-aos-duration="1500"
        >
          <h1 className="text-xl lg:text-2xl 2xl:text-4xl font-bold uppercase w-full lg:text-left text-center">
            About Us
          </h1>
          <p className="py-6 text-sm xl:text-md 2xl:text-lg text-normal w-full lg:text-left text-center">
            We are one of the only NGOs in Indonesia tackling the problems of
            environmental pollution at the household level. In our home of
            Jogjakarta there's an estimated 300,000 heads of cattle. Our biogas
            digesters turn cow waste from a toxic source of water pollution into
            high-quality organic compost that can also fully replace fossil
            fuel-based fertilisers.

          </p>
          <Link
            to={"/about"}
            className="text-center md:text-left btn bg-[#dcfce7] transition ease-in-out lg:text-lg text-sm hover:bg-[#34d399] text-black font-semibold py-2 px-4 border border-black border-b-4 border-r-4 rounded-lg duration-500 mb-10 md:mb-0 hover:border-black"
          >
            About Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
