import { Link } from "react-router-dom";
import Aos from "aos";
import { useEffect } from "react";

const Hero = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div
      className="hero min-h-screen bg-[center_top_1rem]"
      style={{ backgroundImage: "url(images/hero1.jpg)" }}
      id="reserve"
    >
      <div className="hero-overlay bg-opacity-60"></div>
      <div className="hero-content pt-24">
        <div
          className="max-w-xl bg-white bg-opacity-75 py-6 pe-10 ps-5 rounded-lg shadow-inner"
          data-theme="light"
          data-aos="fade-down"
          data-aos-duration="700"
        >
          <h1 className="mb-5 lg:text-4xl text-2xl font-bold">
            Ignite Your Passion for a Career in Sustainability with Our Hands-on
            Renewable Energy Volunteer Internships in Indonesia
          </h1>
          <Link
            to="https://forms.gle/38nKucqhG6cqXkXH6"
            class="bg-[#dcfce7] transition ease-in-out lg:text-lg text-sm hover:bg-[#34d399] text-black font-semibold py-2 px-4 border border-black border-b-4 border-r-4 rounded-lg duration-500"
          >
            Reserve your place
          </Link>
          <p
            className="font-normal lg:text-sm text-xs mt-5"
            style={{ fontWeight: 500 }}
          >
            *Reservations made at least two weeks in advance get a 15% discount.
            Start the reservation process today - you won't be charged until
            your place is confirmed.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
