import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../../index.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Aos from 'aos';
import 'aos/dist/aos.css';

export default function HomeCarousel({ props }) {
  useEffect(()=>{
    Aos.init()
  }, [])

  return (
    // <div className="carousel w-full mt-48">
    //   <div id="slide1" className="carousel-item relative w-full">
    //     <img src="https://daisyui.com/images/stock/photo-1625726411847-8cbb60cc71e6.jpg" className="w-full" />
    //     <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
    //       <a href="#slide4" className="btn btn-circle">❮</a> 
    //       <a href="#slide2" className="btn btn-circle">❯</a>
    //     </div>
    //   </div> 
    //   <div id="slide2" className="carousel-item relative w-full">
    //     <img src="https://daisyui.com/images/stock/photo-1609621838510-5ad474b7d25d.jpg" className="w-full" />
    //     <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
    //       <a href="#slide1" className="btn btn-circle">❮</a> 
    //       <a href="#slide3" className="btn btn-circle">❯</a>
    //     </div>
    //   </div> 
    //   <div id="slide3" className="carousel-item relative w-full">
    //     <img src="https://daisyui.com/images/stock/photo-1414694762283-acccc27bca85.jpg" className="w-full" />
    //     <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
    //       <a href="#slide2" className="btn btn-circle">❮</a> 
    //       <a href="#slide4" className="btn btn-circle">❯</a>
    //     </div>
    //   </div> 
    //   <div id="slide4" className="carousel-item relative w-full">
    //     <img src="https://daisyui.com/images/stock/photo-1665553365602-b2fb8e5d1707.jpg" className="w-full" />
    //     <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
    //       <a href="#slide3" className="btn btn-circle">❮</a> 
    //       <a href="#slide1" className="btn btn-circle">❯</a>
    //     </div>
    //   </div>
    // </div>

    <div className='w-full h-full' data-aos="fade-down-right" data-aos-duration="700">
      <Swiper
        centeredSlides={true}
        height={10}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper object-cover lg:h-3/4 lg:rounded-r-lg lg:shadow-xl"
      >
        {props.map((item, key) => (
          <SwiperSlide key={key}>
            <img src={item.url} alt={item.title} className='object-cover w-full'/>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}