import React, { useEffect } from "react";
import NavbarNow from "../components/Navbar";
import ActivityCard from "../components/homepage/ActivityCard";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Internships = () => {
  useEffect(() => {
    document.title = "Yayasan Rumah Ilham Nusantara";
    window.scrollTo(0, 0);
  }, []);

  const activityList = [
    {
      title: "Biogas Engineering Internship (Short-Term)",
      link: "/short-term-biogas",
      price: "€650 / $680",
      list: [
        "Enhance your CV and gain professional hands-on experience engineering renewable energy projects and having a direct, positive impact on vulnerable communities",
        "Enjoy a 7-day experience in a small village in Java planning, building and starting up a fixed-dome biogas digester",
        "Live in a semi self-sustaining homestay in a touristic paradise in Indonesia in custom-made bamboo accommodation and explore world-famous heritage sites such as the temples of Prambanan and Borobudur",
      ],
      button: "Get Started",
      description: [
        "📍Jogjakarta, Indonesia",
        "📆 4 weeks / 1 month",
        "🏨 Accommodation & 3 meals a day (vegetarian catering available)",
        "🧑‍🎓 Ages 18-50+ (students have preference)",
      ],
    },
    {
      title: "Biogas Engineering Internship (Long-Term)",
      link: "/long-term-biogas",
      price: "€650++ / $680++",
      list: [
        "Enhance your CV and gain professional hands-on experience engineering renewable energy projects and having a direct, positive impact on vulnerable communities",
        "Enjoy a 7-day experience in a small village in Java planning, building and starting up a fixed-dome biogas digester",
        "Live in a semi self-sustaining homestay in a touristic paradise in Indonesia in custom-made bamboo accommodation and explore world-famous heritage sites such as the temples of Prambanan and Borobudur",
        "Get assistance for a visa application and processing services for an extended stay in Indonesia",
        "Stay for six months for internship.",
        "Learn Javanese culture and Indonesian language",
        "Improve your biogas construction skill by visiting several new biogas construction sites",
        "Visit some farmings for seeing slurry applications as organic fertilizer and pesticide",
        "Visit some location in north and south Java for involving in plastic waste collection"
      ],
      button: "Get Started",
    },
    {
      title: "Ocean Plastic Internship (Short & Long-Term)",
      caption:
        "We also clean river and ocean that have been contaminated with plastic wastes",
      alt: "activity3",
      button: "Coming Soon",
    },
  ];

  return (
    <div>
      <div data-theme="light">
        <NavbarNow />
        <div
          className="hero h-96 bg-center"
          style={{
            backgroundImage: "url(images/hero1.jpg)",
            backgroundPositionY: "-200px",
          }}
        >
          <div className="hero-overlay bg-opacity-60"></div>
          <div className="hero-content text-center text-neutral-content">
            <div className="max-w-lg">
              <h1 className="text-5xl font-bold mt-16">
                Reserve Your Place on a Renewable Energy Volunteer Internship
              </h1>
            </div>
          </div>
        </div>
        <div className="bg-[#34d399] pb-10 pt-20">
          <ActivityCard props={activityList} />
          <div className="w-3/4 mx-auto md:w-1/2 text-center mx-auto my-10 bg-white bg-opacity-50 p-4 rounded-md">
            <p className="text-md md:text-xl font-semibold">
              Fill out our short reservation form to make an inquiry about
              availability on your chosen programme between dates that suit you
              to come to Indonesia.
              <br />
              You will receive a confirmation of dates, a fully itemised quote
              and a longer application form.
              <br />
              Upon payment of a small deposit your place will be reserved. You
              can then choose to pay in full in advance or upon arrival.
            </p>
            <Link
              className="mt-5 btn bg-[#dcfce7] transition ease-in-out lg:text-lg text-sm hover:bg-[#34d399] text-black font-semibold py-2 px-4 border border-black border-b-4 border-r-4 rounded-lg duration-500 hover:border-black"
              to="https://forms.gle/38nKucqhG6cqXkXH6"
            >
              Reserve your place
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Internships;
