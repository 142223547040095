import React, { useEffect } from "react";
import NavbarNow from "../components/Navbar";
import CaptionHome from "../components/homepage/CaptionHome";
import Activities from "../components/homepage/Activities";
import Footer from "../components/Footer";
import Map from "../components/homepage/Map";
import Testimony from "../components/homepage/Testimony";
import About from "../components/About";

export default function Homepage(props) {
  useEffect(() => {
    document.title = "Yayasan Rumah Ilham Nusantara";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavbarNow />
      <CaptionHome />
      <Activities />
      <Testimony />
      <About />
      <Map />
      <Footer />
    </>
  );
}
