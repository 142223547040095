import { useEffect } from "react";
import HomeCarousel from "./HomeCarousel";
import Aos from "aos";
import "aos/dist/aos.css";
import Stats from "./Stats";
import Hero from "./Hero";
import Partners from "../Partners";

const CaptionHome = () => {
  const photos = [
    { url: "/images/home-slider/slider1.jpg", title: "gambar1" },
    { url: "/images/home-slider/slider2.jpg", title: "gambar2" },
    { url: "/images/home-slider/slider3.jpg", title: "gambar3" },
    { url: "/images/home-slider/slider4.jpg", title: "gambar4" },
    { url: "/images/home-slider/slider5.jpg", title: "gambar5" },
    { url: "/images/home-slider/slider6.jpg", title: "gambar6" },
    { url: "/images/home-slider/slider7.jpg", title: "gambar7" },
  ];

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      {/* <div id="hero" className="min-h-screen md:bg-cover lg:bg-[center_top_-30rem] bg-center shadow-lg bg-no-repeat bg-[url('../public/images/hero1.jpg')] " data-theme="light" >
                <div className="absolute bg-gray-900 bg-opacity-75 z-5 w-full h-full"></div>
                <div className="w-full max-w-xs h-screen flex flex-col justify-center mx-auto z-100">
                    <h1 className="text-white">Test</h1>
                </div>
            </div> */}
      <Hero />
      <Partners />
      <Stats />
      <div className="bg-[#dcfce7]" data-theme="light">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200">
          <path
            fill="#34d399"
            fill-opacity="1"
            d="M0,192L80,176C160,160,320,128,480,128C640,128,800,160,960,176C1120,192,1280,192,1360,192L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
          ></path>
        </svg>
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:translate-y-[20%]">
          <HomeCarousel props={photos} />
          <div
            className="mx-10 lg:ms-10 lg:me-0 lg:bg-white lg:rounded-l-lg lg:shadow-xl lg:h-fit lg:p-8 lg:bg-opacity-50 z-10 md:translate-y-[-30%] lg:translate-y-0 h-full"
            data-aos="fade-down-left"
            data-aos-duration="700"
          >
            <h1 className="text-xl lg:text-2xl 2xl:text-4xl font-bold uppercase w-full lg:text-left text-center">
              A Cleaner World Starts at Home
            </h1>
            <p className="py-6 text-sm xl:text-md 2xl:text-lg text-normal w-full lg:text-left text-center">
              The Citarum River on the island of Java, Indonesia, became famous
              in 2013 when it was named as one of the 10 most polluted places on
              earth.
              <br />
              <br />
              Water pollution on an island like Java directly impacts the lives
              of up to 145 million people. It's the reason why every year 300
              out of 1,000 Indonesians suffer from water-borne diseases like
              cholera, dysentry and typhoid. It’s also why a freshwater lake
              such as Rawa Pening in Central Java is 70% covered with invasive
              plants and was predicted to dry out by 2021 – cutting off 72
              villages from their primary source of fish and irrigation for
              crops.
              <br />
              <br />
              It was 10 years ago that the World Bank pledged $226 million
              annually to help clean up Java's rivers. But while the government
              has taken steps to place restrictions on factory effluents one
              huge issue remains: livestock waste and chemical fertilisers.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaptionHome;
