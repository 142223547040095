import React from "react";

const Testimony = () => {
  return (
    <div className="bg-[#dcfce7] h-max" data-theme="light">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
        <path
          fill="#34d399"
          fill-opacity="1"
          d="M0,192L60,176C120,160,240,128,360,133.3C480,139,600,181,720,186.7C840,192,960,160,1080,138.7C1200,117,1320,107,1380,101.3L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        ></path>
      </svg>
      <div id="testimony">
        <div className="text-center py-10">
          <h1 className="xl:text-4xl text-xl font-semibold">
            What Previous Interns Say About Rumah Ilham
          </h1>
        </div>
        <div className="pb-4 px-6">
          <div className="md:w-3/4 lg:w-1/2 3xl:w-1/4 w-full bg-gray-50 shadow-lg mx-auto p-5 rounded-md">
            <h1 className="italic text-lg">Joshua Parfitt (United Kingdom)</h1>
            <p className="font-bold">
              Biogas Engineering Internship (Long Term)
            </p>
            <img
              src="/images/testimonial1.jpg"
              alt="testimonial_1"
              className="mx-auto my-3 rounded-md"
            />
            <p>⭐⭐⭐⭐⭐</p>
            <p className="italic">
              It was my last year of university and I felt confused about my
              direction in life. I knew I wanted to do something with a positive
              impact and a short-term environmental volunteering internship
              seemed ideal to see if this was for me. No other renewable energy
              volunteer internship I could find was as hands-on as the program
              offered at Rumah Ilham... But I never actually completed the
              biogas engineering module! Instead, Pak Willem learned I had a
              background in journalism and asked if I wanted to write about his
              incredible NGO instead. Not only did I get to travel to Central
              Java, West Java, East Java, Bali and Lombok visiting and
              interviewing remote villagers but a Singaporean TV crew even flew
              out to film our work! I created two fundraisers that brought in
              almost $10,000 for various environmental projects... And{" "}
              <span className="font-bold">
                I discovered exactly what I wanted to do with my career.
              </span>
              I cannot underestimate the life-changing experience I had at Rumah
              Ilham. Pak Willem, Mas Billy, Mas Basuki, Mas Bambang, Romo
              Phillip and all the beautiful people you meet will have an impact
              on you that lasts for years.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimony;
