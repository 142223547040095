import React from "react";
import {
  PhotoIcon,
  InformationCircleIcon,
  UserGroupIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function NavbarNow() {
  // const [isNavOpen, setIsNavOpen] = React.useState(false);

  // const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);

  // React.useEffect(() => {
  //   window.addEventListener(
  //     "resize",
  //     () => window.innerWidth >= 960 && setIsNavOpen(false),
  //   );
  // }, []);

  const navbarThings = [
    {
      link: "/",
      icon: <HomeIcon className="h-5 w-5 text-black" />,
      name: "Home",
      id: "home",
    },
    {
      link: "/about",
      icon: <InformationCircleIcon className="h-5 w-5 text-black" />,
      name: "About",
      id: "about",
    },
    {
      link: "/gallery",
      icon: <PhotoIcon className="h-5 w-5 text-black" />,
      name: "Gallery",
      id: "gallery",
    },
    {
      link: "/internships",
      icon: <UserGroupIcon className="h-5 w-5 text-black" />,
      name: "Internships",
      id: "internships",
    },
  ];

  return (
    <div className="bg-[#34d399]">
      <div
        className="navbar bg-gray-200 p-5 fixed lg:mx-auto z-20 shadow-xl"
        data-theme="light"
      >
        <div className="navbar-start">
          <div className="dropdown z-50">
            <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>
            <ul className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
              {navbarThings.map((comp, key) => {
                return (
                  <li key={key}>
                    <Link
                      className="font-bold text-lg"
                      to={comp.link}
                      id={comp.id}
                    >
                      {comp.icon}
                      {comp.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <Link
            className="hover:bg-black hover:bg-opacity-10 transition ease-in-out translate-x-[-25%] md:translate-x-0 hover:rounded-lg lg:absolute xl:mx-44 lg:mx-24"
            to="/"
          >
            <img src="./favicon.png" alt="logo" width={100} height={100} />
          </Link>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1">
            {navbarThings.map((comp, key) => {
              return (
                <li key={key}>
                  {
                    <Link
                      className="font-bold text-lg"
                      to={comp.link}
                      id={comp.id}
                    >
                      {comp.icon}
                      {comp.name}
                    </Link>
                  }
                </li>
              );
            })}
          </ul>
        </div>
        <div className="navbar-end">
          <Link
            className="btn bg-[#dcfce7] transition ease-in-out lg:text-lg text-sm hover:bg-[#34d399] text-black font-semibold py-2 px-4 border border-black border-b-4 border-r-4 rounded-lg duration-500 hover:border-black"
            to="https://forms.gle/38nKucqhG6cqXkXH6"
          >
            <span className="text-xs md:text-lg">Reserve your place</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
