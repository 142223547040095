import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "../components/Sidebar";

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Admin Page - Yayasan Rumah Ilham Nusantara";
    window.scrollTo(0, 0);

    axios
      .get("http://localhost:4000/login", { withCredentials: true })
      .then((res) => {
        // navigate("/dashboard");
      })
      .catch((err) => {
        navigate("/admin_login");
      });

    setTimeout(() => setLoading(false), 1000);
  }, [navigate]);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-300" data-theme='light'>
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  return (
    <div data-theme="light" className="bg-gray-300">
      <div className="p-4 fixed">
        <Sidebar />
      </div>
      <div className="h-screen flex items-center justify-center">
        <h1 className="text-2xl font-bold">Rumah Ilham Admin Page</h1>
      </div>
    </div>
  );
};

export default Dashboard;
