import React, { useEffect } from "react";
import Aos from "aos";

const Stats = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="bg-[#34d399]" data-theme="light">
      <div class="px-4 pt-8 mx-auto sm:max-w-xl md:max-w-full md:px-24 lg:px-8 bg-[#34d399]">
        <div class="grid grid-cols-1 sm:grid-cols-2 row-gap-8 gap-y-8 lg:grid-cols-7">
          <div
            class="text-center sm:border-r-[3px] border-black"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">714</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
              of Biogas Digesters Installed
            </p>
          </div>
          <div
            class="text-center lg:border-r-[3px] border-black"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">2.856</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
              of Tonnes of CO2 Captured Per Year
            </p>
          </div>
          <div
            class="text-center sm:border-r-[3px] border-black"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">8</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
              of RIvers Benefited
            </p>
          </div>
          <div
            class="text-center lg:border-r-[3px] border-black"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">16</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
              of Villages Benefited
            </p>
          </div>
          <div
            class="text-center sm:border-r-[3px] border-black"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">200km</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
              of Ocean and Coastal benefited
            </p>
          </div>
          <div
            class="text-center lg:border-r-[3px] border-black"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">281.547</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
              Tons of Plastic Waste Collected From The Rivers
            </p>
          </div>
          <div class="text-center" data-aos="fade-up" data-aos-duration="700">
            <h6 class="text-xl font-bold lg:text-2xl xl:text-3xl">361.273</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-sm">
              Tons of Plastic Waste Collected From The Sea and Along Seashore
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
